exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-500-tsx": () => import("./../../../src/pages/500.tsx" /* webpackChunkName: "component---src-pages-500-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-clinical-resources-tsx": () => import("./../../../src/pages/clinical-resources.tsx" /* webpackChunkName: "component---src-pages-clinical-resources-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-our-approach-tsx": () => import("./../../../src/pages/our-approach.tsx" /* webpackChunkName: "component---src-pages-our-approach-tsx" */),
  "component---src-pages-our-mission-tsx": () => import("./../../../src/pages/our-mission.tsx" /* webpackChunkName: "component---src-pages-our-mission-tsx" */),
  "component---src-pages-our-people-tsx": () => import("./../../../src/pages/our-people.tsx" /* webpackChunkName: "component---src-pages-our-people-tsx" */),
  "component---src-pages-press-tsx": () => import("./../../../src/pages/press.tsx" /* webpackChunkName: "component---src-pages-press-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-privacy-statement-tsx": () => import("./../../../src/pages/privacy-statement.tsx" /* webpackChunkName: "component---src-pages-privacy-statement-tsx" */),
  "component---src-pages-terms-of-use-tsx": () => import("./../../../src/pages/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-tsx" */)
}

